import React, { Component } from "react";
import "../App.css";
import logo from "../images/icon2.png";
import otp from "../apis/otp";
import home from "../apis/home";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      otp: "",
      isRegisteredUser: false,
      userId: "",
      jwtToken: "",
      isUserVerified: false,
      selectedDate: "",
      purchases: [],
      sales: [],
      expenses: [],
      products: [],
      customers: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("ShopManager")) {
      const obj = JSON.parse(localStorage.getItem("ShopManager"));
      this.setState({
        userId: obj.userId,
        isUserVerified: obj.isUserVerified,
        jwtToken: obj.jwtToken,
        mobile: obj.mobile
      });
    }
  }

  updateMobileNo = (event) => {
    let target = event.target;
    let mobile = target.value;
    this.setState({ mobile });
  };

  requestOtp = async (e) => {
    e.preventDefault();
    let response = await otp.requestOtp(this.state.mobile);
    if (response.isBusinessSetup) {
      this.setState({ isRegisteredUser: true });
    }
  };

  updateOtp = (e) => {
    let target = e.target;
    let otp = target.value;
    this.setState({ otp });
  };

  verifyOtp = async (e) => {
    e.preventDefault();
    let response = await otp.verifyOtp(
      this.state.mobile,
      this.state.otp,
      "SMG_Web",
      "Chrome"
    );
    if (response.userId) {
      const ud = {
        userId: response.userId,
        isUserVerified: true,
        jwtToken: response.jwt,
        mobile: this.state.mobile
      };
      localStorage.setItem("ShopManager", JSON.stringify(ud));
      this.setState({
        userId: response.userId,
        isUserVerified: true,
        jwtToken: response.jwt,
        mobile: this.state.mobile
      });
    }
  };

  logout = async () => {
    await home.logoutDevice(this.state.jwtToken);
    localStorage.removeItem("ShopManager");
    this.setState({
      userId: null,
      isUserVerified: false,
      jwtToken: null,
    });
  };

  updateSelectedDate = async (e) => {
    let selectedDate = e.target.value;
    let purchases = await home.getPurchases(
      this.state.userId,
      selectedDate,
      this.state.jwtToken
    );
    let sales = await home.getSales(
      this.state.userId,
      selectedDate,
      this.state.jwtToken
    );
    let expenses = await home.getExpenses(
      this.state.userId,
      selectedDate,
      this.state.jwtToken
    );
    let products = await home.getProducts(
      this.state.userId,
      this.state.jwtToken
    );
    let customers = await home.getCustomers(
      this.state.userId,
      this.state.jwtToken
    );
    products.forEach((p) => {
      p.TypeId = p.Type._id;
      p.TypeName = p.Type.Name;
      const a = p.AlternateUnitConversionStr.split(" ");
      const b = parseFloat(
        (p.InStock * parseFloat(a[3])) / parseFloat(a[0])
      ).toFixed(3);
      p.AltUnit = a[4];
      p.AltQty = b;
      p.AltNetRate = parseFloat(
        parseFloat(p.SellingPrice) * (parseFloat(a[0]) / parseFloat(a[3]))
      ).toFixed(2);
    });
    expenses.forEach((e) => {
      e.TypeId = e.Type._id;
      e.TypeName = e.Type.Name;
    });
    let salesUpdated = [];
    sales.forEach((s) => {
      let productArr = [];
      s.SalesProducts.forEach((p) => {
        productArr.SaleId = s._id;
        productArr.SalesInvoiceID = s.SalesInvoiceID;
        productArr.SalesInvoiceDate = new Date(
          s.SalesInvoiceDate
        ).toLocaleDateString("en-IN", {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        });
        productArr.SalesPaymentType = "Sales " + s.SalesPaymentType + " - SMG";
        productArr.BuyerId = s.SalesTO.CustomerID;
        productArr.BuyerName = s.SalesTO.Name;
        productArr.BuyerPhone = s.SalesTO.ContactNo;
        if (s.SalesTO.Type) {
          if (s.SalesTO.Type === "Buyer") {
            productArr.BuyerType = "Sundry Debtors";
          } else {
            productArr.BuyerType = "Sundry Creditors";
          }
        } else {
          productArr.BuyerType = "Sundry Debtors";
        }
        productArr.BuyerAddr1 = s.SalesTO.AddressLine1;
        productArr.BuyerAddr2 = s.SalesTO.AddressLine2;
        productArr.BuyerLandmark = s.SalesTO.Landmark;
        productArr.BuyerCity = "";
        productArr.BuyerPincode = s.SalesTO.Pincode;
        if (s.SalesTO.State) {
          productArr.BuyerState = s.SalesTO.State;
        } else {
          let cusep = customers.find((x) => x._id === s.SalesTO.CustomerID);
          productArr.BuyerState = cusep.State;
        }
        productArr.BuyerGSTIN = s.SalesTO.GSTNumber;
        productArr.ProductID = p._id;
        productArr.ProductName = p.Name;
        productArr.ProductType = p.Type.Name;
        productArr.ProductCategory = "";
        productArr.ProductHSN = p.HSNCode;
        productArr.ProductGodown = "";
        if (p.ReportingQtyUnit) {
          if (p.ReportingQtyUnit === p.altSecondaryUnit) {
            productArr.UnitofMeasure = p.altSecondaryUnit;
            productArr.Quantity = p.altSecondaryUnitValue;
            productArr.NetRate = p.altSecondaryUnitNetRate;
            productArr.Rate = p.altSecondaryUnitRate;
          } else {
            productArr.UnitofMeasure = p.SellingPriceUnit;
            productArr.Quantity = p.Qty;
            productArr.NetRate = p.SellingPrice;
            productArr.Rate = p.grossTotalBase;
          }
        } else {
          //implementation for data before Reporting Qty Feature! setting value to alternative qty
          let productObj = products.find((element) => element._id === p._id);
          let a = productObj.AlternateUnitConversionStr.split(" ");
          var b = parseFloat(
            (p.Qty * parseFloat(a[3])) / parseFloat(a[0])
          ).toFixed(3);
          productArr.UnitofMeasure = a[4];
          productArr.Quantity = b;
          productArr.NetRate = parseFloat(
            parseFloat(p.SellingPrice) * (parseFloat(a[0]) / parseFloat(a[3]))
          ).toFixed(2);
          productArr.Rate = parseFloat(
            parseFloat(p.grossTotalBase) * (parseFloat(a[0]) / parseFloat(a[3]))
          ).toFixed(2);
        }
        productArr.GrossAmount = p.grossTotal;
        productArr.GSTPercentage = p.GSTPercent;
        productArr.SGSTAmount = p.SGSTAmount;
        productArr.CGSTAmount = p.CGSTAmount;
        productArr.IGSTAmount = p.IGSTAmount;
        productArr.MarketFeeAmount = p.MarketAmount ? p.MarketAmount : 0;
        productArr.CESSAmount = p.CESSAmount ? p.CESSAmount : 0;
        if (productArr.length < 1) {
          productArr.OCHamliAmt = s.OtherCharges[0]
            ? s.OtherCharges[0].amount
            : 0;
          productArr.OCCartageAmt = s.OtherCharges[1]
            ? s.OtherCharges[1].amount
            : 0;
          productArr.OCOthersAmt = s.OtherCharges[2]
            ? s.OtherCharges[2].amount
            : 0;
          productArr.TCSAmt = s.OtherCharges[3] ? s.OtherCharges[3].amount : 0;
          productArr.RoundOffAmt = s.RoundOffAmt ? s.RoundOffAmt : 0;
          productArr.ExtraChargesAmt = s.ExtraChargesAmt
            ? s.ExtraChargesAmt
            : 0;
          productArr.LineTotalAmount =
            parseFloat(p.total) +
            parseFloat(productArr.OCHamliAmt) +
            parseFloat(productArr.OCCartageAmt) +
            parseFloat(productArr.OCOthersAmt) +
            parseFloat(productArr.TCSAmt) +
            parseFloat(productArr.RoundOffAmt) +
            parseFloat(productArr.ExtraChargesAmt);
        } else {
          productArr.OCHamliAmt = 0;
          productArr.OCCartageAmt = 0;
          productArr.OCOthersAmt = 0;
          productArr.TCSAmt = 0;
          productArr.RoundOffAmt = 0;
          productArr.ExtraChargesAmt = 0;
          productArr.LineTotalAmount = p.total;
        }
        productArr.Declaration =
          s.SalesPaymentType === "Cash"
            ? s.CashDeclaration
            : s.CreditDeclaration;
        productArr.BatchNum = "";
        productArr.mnfDate = "";
        productArr.ExpiryDate = "";
        productArr.Notes = s.Notes;
        productArr.BrokerId = "";
        productArr.BrokerName = "";
        productArr = [...productArr, productArr];
      });
      salesUpdated = [...salesUpdated, ...productArr];
    });
    let purchaseUpdated = [];
    purchases.forEach((s) => {
      let productArr = [];
      s.PurchaseProducts.forEach((p) => {
        productArr.SaleId = s._id;
        if (s.voucherID) {
          productArr.VoucherId = s.voucherID;
        } else {
          productArr.VoucherId = "";
        }
        productArr.SalesInvoiceID = s.RefInvoiceID;
        productArr.RefBillDate = new Date(s.RefBillDate).toLocaleDateString(
          "en-IN",
          {
            year: "2-digit",
            month: "short",
            day: "2-digit",
          }
        );
        productArr.SalesInvoiceDate = new Date(
          s.ReceivedDate
        ).toLocaleDateString("en-IN", {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        });
        productArr.SalesPaymentType = "Purchase - SMG";
        productArr.BuyerId = s.PurchasedFrom.CustomerID;
        productArr.BuyerName = s.PurchasedFrom.Name;
        productArr.BuyerPhone = s.PurchasedFrom.ContactNo;
        if (s.PurchasedFrom.Type) {
          if (s.PurchasedFrom.Type === "Buyer") {
            productArr.BuyerType = "Sundry Debtors";
          } else {
            productArr.BuyerType = "Sundry Creditors";
          }
        } else {
          productArr.BuyerType = "Sundry Creditors";
        }
        productArr.BuyerAddr1 = s.PurchasedFrom.AddressLine1;
        productArr.BuyerAddr2 = s.PurchasedFrom.AddressLine2;
        productArr.BuyerLandmark = s.PurchasedFrom.Landmark;
        productArr.BuyerCity = "";
        productArr.BuyerPincode = s.PurchasedFrom.Pincode;
        if (s.PurchasedFrom.State) {
          productArr.BuyerState = s.PurchasedFrom.State;
        } else {
          let cusep = customers.find(
            (x) => x._id === s.PurchasedFrom.CustomerID
          );
          productArr.BuyerState = cusep.State;
        }
        productArr.BuyerGSTIN = s.PurchasedFrom.GSTNumber;
        productArr.ProductID = p._id;
        productArr.ProductName = p.Name;
        productArr.ProductType = p.Type.Name;
        productArr.ProductCategory = "";
        productArr.ProductHSN = p.HSNCode;
        productArr.ProductGodown = "";
        if (p.ReportingQtyUnit) {
          if (p.ReportingQtyUnit === p.altSecondaryUnit) {
            productArr.UnitofMeasure = p.altSecondaryUnit;
            productArr.Quantity = p.altSecondaryUnitValue;
            productArr.NetRate = p.altSecondaryUnitNetRate;
            productArr.Rate = p.altSecondaryUnitRate;
          } else {
            productArr.UnitofMeasure = p.SellingPriceUnit;
            productArr.Quantity = p.Qty;
            productArr.NetRate = p.SellingPrice;
            productArr.Rate = p.grossTotalBase;
          }
        } else {
          //implementation for data before Reporting Qty Feature! setting value to alternative qty
          let productObj = products.find((element) => element._id === p._id);
          let a = productObj.AlternateUnitConversionStr.split(" ");
          var b = parseFloat(
            (p.Qty * parseFloat(a[3])) / parseFloat(a[0])
          ).toFixed(3);
          productArr.UnitofMeasure = a[4];
          productArr.Quantity = b;
          productArr.NetRate = parseFloat(
            parseFloat(p.SellingPrice) * (parseFloat(a[0]) / parseFloat(a[3]))
          ).toFixed(2);
          productArr.Rate = parseFloat(
            parseFloat(p.grossTotalBase) * (parseFloat(a[0]) / parseFloat(a[3]))
          ).toFixed(2);
        }
        productArr.GrossAmount = p.grossTotal;
        productArr.GSTPercentage = p.GSTPercent;
        productArr.SGSTAmount = p.SGSTAmount;
        productArr.CGSTAmount = p.CGSTAmount;
        productArr.IGSTAmount = p.IGSTAmount;
        productArr.MarketFeeAmount = p.MarketAmount ? p.MarketAmount : 0;
        productArr.CESSAmount = p.CESSAmount ? p.CESSAmount : 0;
        if (productArr.length < 1) {
          productArr.OCHamliAmt = s.OtherCharges[0]
            ? s.OtherCharges[0].amount
            : 0;
          productArr.OCCartageAmt = s.OtherCharges[1]
            ? s.OtherCharges[1].amount
            : 0;
          productArr.OCOthersAmt = s.OtherCharges[2]
            ? s.OtherCharges[2].amount
            : 0;
          productArr.TCSAmt = s.OtherCharges[3] ? s.OtherCharges[3].amount : 0;
          productArr.RoundOffAmt = s.RoundOffAmt ? s.RoundOffAmt : 0;
          productArr.ExtraChargesAmt = s.ExtraChargesAmt
            ? s.ExtraChargesAmt
            : 0;
          productArr.LineTotalAmount =
            parseFloat(p.total) +
            parseFloat(productArr.OCHamliAmt) +
            parseFloat(productArr.OCCartageAmt) +
            parseFloat(productArr.OCOthersAmt) +
            parseFloat(productArr.TCSAmt) +
            parseFloat(productArr.RoundOffAmt) +
            parseFloat(productArr.ExtraChargesAmt);
        } else {
          productArr.OCHamliAmt = 0;
          productArr.OCCartageAmt = 0;
          productArr.OCOthersAmt = 0;
          productArr.TCSAmt = 0;
          productArr.RoundOffAmt = 0;
          productArr.ExtraChargesAmt = 0;
          productArr.LineTotalAmount = p.total;
        }
        productArr.Declaration =
          s.SalesPaymentType === "Cash"
            ? s.CashDeclaration
            : s.CreditDeclaration;
        productArr.BatchNum = "";
        productArr.mnfDate = "";
        productArr.ExpiryDate = "";
        productArr.Notes = s.Notes;
        productArr.BrokerId = "";
        productArr.BrokerName = "";
        productArr = [...productArr, productArr];
      });
      purchaseUpdated = [...purchaseUpdated, ...productArr];
    });
    this.setState({
      selectedDate,
      purchases: purchaseUpdated,
      sales: salesUpdated,
      expenses,
      products,
      customers,
    });
  };

  render() {
    if (this.state.isUserVerified) {
      return (
        <div className="App">
          <img src={logo} alt="logo"></img>
          <br />
          <label>{this.state.mobile}</label>
          <br />
          <label>Select Date To Download excel file</label>
          <br />
          <input
            type="date"
            id="dt"
            onChange={this.updateSelectedDate}
            value={this.state.selectedDate}
          />
          <br />

          <ExcelFile filename={this.state.selectedDate}>
            <ExcelSheet data={this.state.sales} name="Sales">
              <ExcelColumn label="Sales ID (Ref.)" value="SaleId"></ExcelColumn>
              <ExcelColumn
                label="Invoice ID"
                value="SalesInvoiceID"
              ></ExcelColumn>
              <ExcelColumn
                label="Voucher Date"
                value="SalesInvoiceDate"
              ></ExcelColumn>
              <ExcelColumn
                label="Invoice Date"
                value="SalesInvoiceDate"
              ></ExcelColumn>
              <ExcelColumn
                label="Transaction Type"
                value="SalesPaymentType"
              ></ExcelColumn>
              <ExcelColumn label="Buyer ID" value="BuyerId"></ExcelColumn>
              <ExcelColumn label="Buyer Name" value="BuyerName"></ExcelColumn>
              <ExcelColumn
                label="Buyer Ph Number"
                value="BuyerPhone"
              ></ExcelColumn>
              <ExcelColumn label="Buyer Type" value="BuyerType"></ExcelColumn>
              <ExcelColumn
                label="Address Line 1"
                value="BuyerAddr1"
              ></ExcelColumn>
              <ExcelColumn
                label="Address Line 2"
                value="BuyerAddr2"
              ></ExcelColumn>
              <ExcelColumn label="LandMark" value="BuyerLandmark"></ExcelColumn>
              <ExcelColumn label="City" value="BuyerCity"></ExcelColumn>
              <ExcelColumn label="Pincode" value="BuyerPincode"></ExcelColumn>
              <ExcelColumn label="State" value="BuyerState"></ExcelColumn>
              <ExcelColumn label="GST No" value="BuyerGSTIN"></ExcelColumn>
              <ExcelColumn label="Product ID" value="ProductID"></ExcelColumn>
              <ExcelColumn
                label="Product Description"
                value="ProductName"
              ></ExcelColumn>
              <ExcelColumn
                label="Product Type"
                value="ProductType"
              ></ExcelColumn>
              <ExcelColumn
                label="Product Category"
                value="ProductCategory"
              ></ExcelColumn>
              <ExcelColumn label="HSN Code" value="ProductHSN"></ExcelColumn>
              <ExcelColumn label="Godown" value="ProductGodown"></ExcelColumn>
              <ExcelColumn
                label="Unit of Measure"
                value="UnitofMeasure"
              ></ExcelColumn>
              <ExcelColumn label="Quantity" value="Quantity"></ExcelColumn>
              <ExcelColumn
                label="Net Rate (Incl. Tax)"
                value="NetRate"
              ></ExcelColumn>
              <ExcelColumn label="Rate (Excl. Tax)" value="Rate"></ExcelColumn>
              <ExcelColumn
                label="Gross Amount"
                value="GrossAmount"
              ></ExcelColumn>
              <ExcelColumn label="GST %" value="GSTPercentage"></ExcelColumn>
              <ExcelColumn label="SGST Amount" value="SGSTAmount"></ExcelColumn>
              <ExcelColumn label="CGST Amount" value="CGSTAmount"></ExcelColumn>
              <ExcelColumn label="IGST Amount" value="IGSTAmount"></ExcelColumn>
              <ExcelColumn
                label="Market Fee Amount"
                value="MarketFeeAmount"
              ></ExcelColumn>
              <ExcelColumn label="CESS Amount" value="CESSAmount"></ExcelColumn>
              <ExcelColumn
                label="OC Hamli Amount"
                value="OCHamliAmt"
              ></ExcelColumn>
              <ExcelColumn
                label="OC Cartage Amount"
                value="OCCartageAmt"
              ></ExcelColumn>
              <ExcelColumn
                label="OC Others Amount"
                value="OCOthersAmt"
              ></ExcelColumn>
              <ExcelColumn label="TCS Amount" value="TCSAmt"></ExcelColumn>
              <ExcelColumn label="Round Off" value="RoundOffAmt"></ExcelColumn>
              <ExcelColumn
                label="Extra Charges"
                value="ExtraChargesAmt"
              ></ExcelColumn>
              <ExcelColumn
                label="Line Total"
                value="LineTotalAmount"
              ></ExcelColumn>
              <ExcelColumn
                label="Declaration"
                value="Declaration"
              ></ExcelColumn>
              <ExcelColumn label="Batch Num" value="BatchNum"></ExcelColumn>
              <ExcelColumn label="mnf Date" value="mnfDate"></ExcelColumn>
              <ExcelColumn label="Expiry Date" value="ExpiryDate"></ExcelColumn>
              <ExcelColumn label="Other Notes" value="Notes"></ExcelColumn>
              <ExcelColumn label="Broker ID" value="BrokerId"></ExcelColumn>
              <ExcelColumn label="Broker Name" value="BrokerName"></ExcelColumn>
            </ExcelSheet>
            <ExcelSheet data={this.state.purchases} name="Purchase">
              <ExcelColumn label="Purchase ID" value="VoucherId"></ExcelColumn>
              <ExcelColumn
                label="Ref. Invoice ID"
                value="SalesInvoiceID"
              ></ExcelColumn>
              <ExcelColumn
                label="Invoice Date"
                value="SalesInvoiceDate"
              ></ExcelColumn>
              <ExcelColumn
                label="Voucher Date"
                value="RefBillDate"
              ></ExcelColumn>
              <ExcelColumn
                label="Transaction Type"
                value="SalesPaymentType"
              ></ExcelColumn>
              <ExcelColumn label="Buyer ID" value="BuyerId"></ExcelColumn>
              <ExcelColumn label="Buyer Name" value="BuyerName"></ExcelColumn>
              <ExcelColumn
                label="Buyer Ph Number"
                value="BuyerPhone"
              ></ExcelColumn>
              <ExcelColumn label="Buyer Type" value="BuyerType"></ExcelColumn>
              <ExcelColumn
                label="Address Line 1"
                value="BuyerAddr1"
              ></ExcelColumn>
              <ExcelColumn
                label="Address Line 2"
                value="BuyerAddr2"
              ></ExcelColumn>
              <ExcelColumn label="LandMark" value="BuyerLandmark"></ExcelColumn>
              <ExcelColumn label="City" value="BuyerCity"></ExcelColumn>
              <ExcelColumn label="Pincode" value="BuyerPincode"></ExcelColumn>
              <ExcelColumn label="State" value="BuyerState"></ExcelColumn>
              <ExcelColumn label="GST No" value="BuyerGSTIN"></ExcelColumn>
              <ExcelColumn label="Product ID" value="ProductID"></ExcelColumn>
              <ExcelColumn
                label="Product Description"
                value="ProductName"
              ></ExcelColumn>
              <ExcelColumn
                label="Product Type"
                value="ProductType"
              ></ExcelColumn>
              <ExcelColumn
                label="Product Category"
                value="ProductCategory"
              ></ExcelColumn>
              <ExcelColumn label="HSN Code" value="ProductHSN"></ExcelColumn>
              <ExcelColumn label="Godown" value="ProductGodown"></ExcelColumn>
              <ExcelColumn
                label="Unit of Measure"
                value="UnitofMeasure"
              ></ExcelColumn>
              <ExcelColumn label="Quantity" value="Quantity"></ExcelColumn>
              <ExcelColumn
                label="Net Rate (Incl. Tax)"
                value="NetRate"
              ></ExcelColumn>
              <ExcelColumn label="Rate (Excl. Tax)" value="Rate"></ExcelColumn>
              <ExcelColumn
                label="Gross Amount"
                value="GrossAmount"
              ></ExcelColumn>
              <ExcelColumn label="GST %" value="GSTPercentage"></ExcelColumn>
              <ExcelColumn label="SGST Amount" value="SGSTAmount"></ExcelColumn>
              <ExcelColumn label="CGST Amount" value="CGSTAmount"></ExcelColumn>
              <ExcelColumn label="IGST Amount" value="IGSTAmount"></ExcelColumn>
              <ExcelColumn
                label="Market Fee Amount"
                value="MarketFeeAmount"
              ></ExcelColumn>
              <ExcelColumn label="CESS Amount" value="CESSAmount"></ExcelColumn>
              <ExcelColumn
                label="OC Hamli Amount"
                value="OCHamliAmt"
              ></ExcelColumn>
              <ExcelColumn
                label="OC Cartage Amount"
                value="OCCartageAmt"
              ></ExcelColumn>
              <ExcelColumn
                label="OC Others Amount"
                value="OCOthersAmt"
              ></ExcelColumn>
              <ExcelColumn label="TCS Amount" value="TCSAmt"></ExcelColumn>
              <ExcelColumn label="Round Off" value="RoundOffAmt"></ExcelColumn>
              <ExcelColumn
                label="Extra Charges"
                value="ExtraChargesAmt"
              ></ExcelColumn>
              <ExcelColumn
                label="Line Total"
                value="LineTotalAmount"
              ></ExcelColumn>
              <ExcelColumn
                label="Declaration"
                value="Declaration"
              ></ExcelColumn>
              <ExcelColumn label="Batch Num" value="BatchNum"></ExcelColumn>
              <ExcelColumn label="mnf Date" value="mnfDate"></ExcelColumn>
              <ExcelColumn label="Expiry Date" value="ExpiryDate"></ExcelColumn>
              <ExcelColumn label="Other Notes" value="Notes"></ExcelColumn>
              <ExcelColumn label="Broker ID" value="BrokerId"></ExcelColumn>
              <ExcelColumn label="Broker Name" value="BrokerName"></ExcelColumn>
              <ExcelColumn
                label="Purchase ID (Ref.)"
                value="SaleId"
              ></ExcelColumn>
            </ExcelSheet>
            <ExcelSheet data={this.state.products} name="Inventory">
              <ExcelColumn label="ProductID" value="_id"></ExcelColumn>
              <ExcelColumn label="Name" value="Name"></ExcelColumn>
              <ExcelColumn label="InStock" value="InStock"></ExcelColumn>
              <ExcelColumn
                label="Selling Unit"
                value="SellingPriceUnit"
              ></ExcelColumn>
              <ExcelColumn
                label="Selling Net Rate (Incl. GST)"
                value="SellingPrice"
              ></ExcelColumn>
              <ExcelColumn label="TypeID" value="TypeId"></ExcelColumn>
              <ExcelColumn label="Type Name" value="TypeName"></ExcelColumn>
              <ExcelColumn label="Brand" value="Brand"></ExcelColumn>
              <ExcelColumn label="GST Percent" value="GSTPercent"></ExcelColumn>
              <ExcelColumn label="HSN Code" value="HSNCode"></ExcelColumn>
              <ExcelColumn label="Status" value="Status"></ExcelColumn>
              <ExcelColumn
                label="Alt Net Rate (Incl. GST)"
                value="AltNetRate"
              ></ExcelColumn>
              <ExcelColumn
                label="Alt Qty(In Stock)"
                value="AltQty"
              ></ExcelColumn>
              <ExcelColumn label="Alt Unit" value="AltUnit"></ExcelColumn>
            </ExcelSheet>
            <ExcelSheet data={this.state.customers} name="Customers">
              <ExcelColumn label="ID" value="_id"></ExcelColumn>
              <ExcelColumn label="Name" value="Name"></ExcelColumn>
              <ExcelColumn label="Contact No" value="ContactNo"></ExcelColumn>
              <ExcelColumn label="Type" value="Type"></ExcelColumn>
              <ExcelColumn
                label="Address Line 1"
                value="AddressLine1"
              ></ExcelColumn>
              <ExcelColumn
                label="Address Line 2"
                value="AddressLine2"
              ></ExcelColumn>
              <ExcelColumn label="Land-Mark" value="Landmark"></ExcelColumn>
              <ExcelColumn label="City" value="City"></ExcelColumn>
              <ExcelColumn label="Pincode" value="Pincode"></ExcelColumn>
              <ExcelColumn label="State" value="State"></ExcelColumn>
              <ExcelColumn label="GST No" value="GSTNumber"></ExcelColumn>
            </ExcelSheet>
            <ExcelSheet data={this.state.expenses} name="Expenses">
              <ExcelColumn label="Expense Id" value="_id"></ExcelColumn>
              <ExcelColumn
                label="Expense Date"
                value="ExpenseDate"
              ></ExcelColumn>
              <ExcelColumn label="Type ID" value="TypeId"></ExcelColumn>
              <ExcelColumn label="Type Name" value="TypeName"></ExcelColumn>
              <ExcelColumn label="Expense Amount" value="Amount"></ExcelColumn>
              <ExcelColumn label="Notes" value="Description"></ExcelColumn>
            </ExcelSheet>
          </ExcelFile>
          <br />
          <br />
          <button onClick={this.logout}>Logout </button>
        </div>
      );
    } else {
      if (!this.state.isRegisteredUser) {
        return (
          <div className="App">
            <img src={logo} alt="logo"></img>
            <form onSubmit={this.requestOtp}>
              <label>Enter Mobile No:</label>
              <br />
              <input
                id="mobile"
                type="tel"
                pattern="^[6-9]\d{9}$"
                maxLength={10}
                minLength={10}
                required
                value={this.state.mobile}
                onChange={this.updateMobileNo}
              ></input>
              <br />
              <input value="Send OTP" type="submit"></input>
            </form>
          </div>
        );
      } else {
        return (
          <div className="App">
            <img src={logo} alt="logo"></img>
            <form onSubmit={this.verifyOtp}>
              <label>Enter Mobile No:</label>
              <br />
              <input
                id="mobile"
                type="tel"
                pattern="^[6-9]\d{9}$"
                maxLength={10}
                minLength={10}
                required
                value={this.state.mobile}
                onChange={this.updateMobileNo}
              ></input>
              <br />
              <input
                id="otp"
                type="text"
                pattern="\d*"
                maxLength="6"
                required
                value={this.state.otp}
                onChange={this.updateOtp}
              ></input>
              <br />
              <input value="Verify OTP" type="submit"></input>
            </form>
          </div>
        );
      }
    }
  }
}

export default Login;
