import constants from "../constants";
import axios from "axios";

const home = {
  getExpenses: async (userID, expenseDate, token) => {
    try {
      let result = await axios.get(
        constants.apiURL + "getExpense/" + userID + "/" + expenseDate,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getPurchases: async (userID, purchaseDate, token) => {
    try {
      let result = await axios.get(
        constants.apiURL + "getPurchaseEntry/" + userID + "/" + purchaseDate,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getSales: async (userID, saleDate, token) => {
    try {
      let result = await axios.get(
        constants.apiURL + "getSalesEntry/" + userID + "/" + saleDate,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getProducts: async (userID, token) => {
    try {
      let result = await axios.get(constants.apiURL + "getProducts/" + userID, {
        headers: { Authorization: "Bearer " + token },
      });
      return result.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  getCustomers: async (userID, token) => {
    try {
      let result = await axios.get(
        constants.apiURL + "getCustomers/" + userID,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  logoutDevice: async (token) => {
    try {
      var result = await axios({
        method: "post",
        url: constants.apiURL + "logout",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      return result.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default home;
