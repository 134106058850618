import React, { useState } from 'react';
import Navigation from './navigation';
import Header from './header';
import Features from './features';
import About from './about';
import Testimonials from './testimonials';
import Contact from './contact';
import Login from './Login';

export default function Website() {

  const [login, setLogin] = useState(false);

  return (
    <div>
      {login ? 
        <Login />
        :
        <>
          <Navigation setLogin={setLogin}/>
          <Header />
          <Features />
          <About />
          <Testimonials />
          <Contact />
        </>
      }
    </div>
  )
}
