import React, { Component } from 'react'

export class features extends Component {
  render() {
    return (
        <div id="features" className="text-center">
            <div className="container">
                <div className="col-md-10 col-md-offset-1 section-title">
                    <h2>Features</h2>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-shopping-cart"></i>
                        <h3>Inventory Management</h3>
                        <p>Makes management easy with low/out of stock.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-shopping-cart"></i>
                        <h3>Purchase and Sales</h3>
                        <p>Manage your purchases and sales through the same app.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-rupee"></i>
                        <h3>30 days free trail</h3>
                        <p>Gives full access of the app for a complete 30 days free trail.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-desktop"></i>
                        <h3>Import to Tally</h3>
                        <p>Ability to import data related to sales, purchase and products into Tally.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-file-excel-o"></i>
                        <h3>Export to Excel</h3>
                        <p>All the purchase, sales and products data can be exported to excel.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-share-alt"></i>
                        <h3>Share Bills</h3>
                        <p>Share your bills to what's app.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-user"></i>
                        <h3>One Account</h3>
                        <p>One Account with multiple devices support.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-lock"></i>
                        <h3>100% Security</h3>
                        <p>100% data security with the standards followed.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-mobile"></i>
                        <h3>Secure Login</h3>
                        <p>Secured login with otp.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-mobile"></i>
                        <h3>Simplified UI</h3>
                        <p>Hasslefree user interface.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-phone"></i>
                        <h3>Premium Support</h3>
                        <p>Premium support in excel and tally.</p>
                    </div>
                    <div className="col-xs-6 col-md-3"> <i className="fa fa-eye"></i>
                        <h3>View Anytime</h3>
                        <p>Get access to your app anytime anywhere.</p>
                    </div>
                </div>
            </div>
      </div>
    )
  }
}

export default features