import React, { Component } from "react";
import smg from "../images/SM-1024px.png";

export class about extends Component {
  render() {
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src={smg} style={{borderRadius: '50%'}} className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>About Us</h2>
                <p style={{fontSize: '2rem'}}>
                    Shop Manager is easy to use and keep track of Sales, Purchase, Inventory – and even simpler for employees to use it.
                    <br />
                    <br />
                    It speeds up business with automatic calculations & digitally manage, create, view, share and print GST bills in both Cash/Credit and connect to Excel / Tally that you already know and use.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default about;
