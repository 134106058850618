import constants from "../constants";
import axios from "axios";

const otp = {
  requestOtp: async (mobile) => {
    try {
      let result = await axios.post(constants.apiURL + "requestOtp", {
        mobile,
      });
      return result.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  verifyOtp: async (mobile, otp, deviceId, brand) => {
    try {
      let result = await axios.post(constants.apiURL + "verifyOtp", {
        mobile,
        otp,
        deviceId,
        brand,
      });
      return result.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};

export default otp;
