import React, { Component } from 'react'

export class testimonials extends Component {
  render() {
    return (
        <div id="testimonials">
        <div className="container">
          <div className="section-title text-center">
            <h2>What our clients say</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
                <div className="testimonial">
                    <div className="testimonial-image"> <img src="https://i.picsum.photos/id/756/200/300.jpg" alt=""/> </div>
                    <div className="testimonial-content">
                        <p>"App is very simple and easy to use. It made inventory management, billing easy. Highly recommended <span role="img" aria-label="good">👌</span> keep up the good work 👍"</p>
                        <div className="testimonial-meta"> - shiva chittiprolu </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="testimonial">
                    <div className="testimonial-image"> <img src="https://i.picsum.photos/id/756/200/300.jpg" alt=""/> </div>
                    <div className="testimonial-content">
                        <p>"Amazing and easy to use!!! Without a doubt it replaces traditional books in digital ways!!"</p>
                        <div className="testimonial-meta"> - Sahil Baig </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="testimonial">
                    <div className="testimonial-image"> <img src="https://i.picsum.photos/id/756/200/300.jpg" alt=""/> </div>
                    <div className="testimonial-content">
                        <p>"Simple to use and perfectly getting import to tally as well. Amazing app <span role="img" aria-label="">👌</span> Thank you"</p>
                        <div className="testimonial-meta"> - Ayush Agarwal </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="testimonial">
                    <div className="testimonial-image"> <img src="https://i.picsum.photos/id/756/200/300.jpg" alt=""/> </div>
                    <div className="testimonial-content">
                        <p>"Quiet Simple and best for keep track of your well defined Inventory, Keep update this app with some more cool features."</p>
                        <div className="testimonial-meta"> - ullas chandrakar </div>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="testimonial">
                    <div className="testimonial-image"> <img src="https://i.picsum.photos/id/756/200/300.jpg" alt=""/> </div>
                    <div className="testimonial-content">
                        <p>"Helps me keep track of the inventory I have love it thank you"</p>
                        <div className="testimonial-meta"> - Cool Gadgets </div>
                    </div>
                </div>
            </div>
          
          </div>
        </div>
      </div>
    )
  }
}

export default testimonials