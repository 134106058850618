import React, { Component } from 'react'

export class Header extends Component {
  render() {
    return (
        <header id="header">
            <div className="intro">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 intro-text">
                            <h1>Shop Manager<span></span></h1>
                            <p>An app to create, print and share bills with a 30 day free trail.</p>
                            <a href="#features" className="btn btn-custom btn-lg page-scroll">Learn More</a> </div>
                        </div>
                    </div>
                </div>
            </div>
      </header>
    )
  }
}

export default Header