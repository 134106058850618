import React, {Component} from 'react';
import './App.css';
import Website from './components/Website';

class App extends Component {

  render(){
    return(
      <Website />
    )
  }
  
}

export default App;
